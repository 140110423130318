@use "../utils/variables";
@use "../utils/mixins";

.btn {
    display: inline-flex;
    font: inherit;
    padding: 1rem;
    border-radius: variables.$default-border-radius;
    outline: none;
    border: none;
    align-items: center;
    position: relative;

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: default;
    }

    &.btn-primary {
        background: variables.$primary-color;
        color: #fff;

        &:hover {
            background: variables.$primary-color-hover;
        }

        &:active {
            background: variables.$primary-color-active;
        }

        &:focus {
            box-shadow: 0 0 0 2px variables.$primary-color-shadow;
        }

        &:disabled {
            background: variables.$primary-color-transparent;
        }

        &.btn-loading:after {
            background: variables.$primary-color;
        }
    }

    &.btn-secondary {
        color: variables.$primary-color;

        &:hover:not(:disabled) {
            background: variables.$primary-color-transparent;
        }

        &:active {
            background: variables.$primary-color-transparent-active;
        }
        &:disabled {
            color: variables.$primary-color-transparent;
        }
    }

    &.btn-danger {
        color: variables.$danger-color;

        &:hover:not(:disabled) {
            background: variables.$danger-color-transparent;
        }

        &:active {
            background: variables.$primary-color-transparent-active;
        }
        &:disabled {
            color: variables.$danger-color-transparent;
        }

        &.btn-loading:after {
            background: variables.$danger-color;
        }
    }

    &.btn-light:not(:disabled):hover {
        background: variables.$transparent-color-hover;
    }

    &.btn-white {
        background: #fff;
        border: solid 1px variables.$border-color;
    }

    &.active {
        box-shadow: 0 0 0 2px variables.$primary-color-shadow;
    }

    .icon-container {
        margin-right: 0.5rem;
    }

    &.btn-loading {
        cursor: wait;

        &:after {
            animation: loading 1s infinite;
            border-radius: variables.$default-border-radius;
            content: '';
            display: block;
            height: 4px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &.btn-link {
        padding: 0;
        color: variables.$primary-color;

        &:hover {
            text-decoration: underline;
        }
    }

    &.btn-small {
        padding: 0.5rem;
    }
}
