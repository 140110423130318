.has-modal {
    overflow: hidden;
}
.modal-backdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, .5);
    top: 0;
    left: 0;
    z-index: 100;
}

.modal-container {
    position: fixed;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 0;
    display: block;
    overflow: auto;
    z-index: 101;

    .modal-content {
        max-width: 500px;
        margin-top: 1rem;
        margin-bottom: 1rem;

        &.horizontal-center {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
