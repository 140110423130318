@use "../utils/variables";

.standalone-page {
    &.center {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
    }
}

.page {
    display: flex;
    flex-direction: row;
    height: 100vh;

    .page-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: auto;
    }

    .page-body {
        padding: 2rem;
    }

    .page-header {
        background: #fff;
        padding: 2rem;
        border-bottom: solid 1px variables.$border-color;
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        z-index: 99;

        .page-header-actions {
            margin-left: auto;
            display: flex;
            align-items: stretch;
        }
    }
}
