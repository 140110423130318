@use "../utils/variables";

.page-navigation {
    background: #f8f8f9;
    padding: 0.5rem;
    width: 100%;
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    border-right: solid 1px variables.$border-color;

    .company-logo {
        text-align: center;
    }

    .page-navigation-divider {
        height: 1px;
        width: 100%;
        background: variables.$transparent-color-hover;
        margin-bottom: 1rem;
    }

    .page-navigation-list {
        height: 100%;
        overflow-y: auto;

        .page-navigation-item {
            display: flex;
            align-items: center;
            padding: 1rem;
            border-radius: variables.$default-border-radius;
            font-weight: 600;

            .page-navigation-icon {
                margin-right: 1.2rem;
            }

            &:not(.group-title) {
                margin-bottom: 1rem;

                &:hover:not(.active) {
                    background: variables.$transparent-color-hover;
                }
            }

            &.active {
                color: variables.$primary-color;
                background: variables.$primary-color-shadow;
            }
        }

        .page-navigation-group-title {
            text-transform: uppercase;
            font-size: 0.9rem;
            font-weight: 600;
            color: variables.$default-color-lighter;
            margin-bottom: 0.5rem;
        }
    }
}

.page-navigation-user-area {
    margin-top: 2rem;
    border-top: solid 1px variables.$transparent-color-hover;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
