.uses-company {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .uses-company-no-company-img {
        width: 100%;
    }
}
