@use "../utils/variables";

.invoice-actions {
    position: sticky;
    top: 0;
    background: variables.$body-background-color;
}

.invoice-width {
    width: 21cm;
}

.invoice-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
}

.invoice-wrapper {
    @extend .invoice-width;
    min-height: 29.7cm;
    padding: 27mm 16mm 27mm 16mm;
    position: relative;

    .customer-section {
        margin: 2rem 0;
    }

    .invoice-items-header {
        display: flex;
        margin-bottom: 1rem;

        .invoice-items-header-item {
            width: 18.8%;
            padding-right: 1rem;

            &:last-child {
                width: 6%;
            }
        }
    }

    .invoice-items {
        margin-bottom: 1rem;

        .invoice-item {
            display: flex;
            align-items: center;
            //justify-content: space-between;
            position: relative;

            .drag-handle {
                position: absolute;
                left: -1rem;
                cursor: grab;
            }

            .form-group {
                margin-bottom: 0;
                width: 18.8%;
                padding-right: 1rem;
            }

            .invoice-item-actions {
                width: 6%;
            }
        }
    }

    .totals-container {
        margin-left: 37.6%;
        width: 37.6%;
        display: flex;
        justify-content: space-between;
        padding-right: 1rem;
        margin-bottom: 1rem;

        .totals-container-part {
            width: calc(50% - (1rem / 2));
            display: flex;
            align-items: center;
        }
    }

    &.invoice-wrapper-disabled:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.5);
    }
}

.invoice-number {
    font-size: 1.5rem;
    font-weight: 500;
}
