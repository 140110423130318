@use "../utils/variables";

table.table {
    width: 100%;

    thead {
        th {
            padding: 0.5rem;

            &:not(.text-right) {
                text-align: left;
            }
        }
    }

    tbody {
        tr {
            position: relative;
        }

        td {
            padding: 0.5rem;
        }
    }

    &.table-clickable tbody tr {
        cursor: pointer;

        &:hover {
            background: variables.$primary-color-transparent;

        }
    }
}

table.items-table {
    border-collapse: collapse;

    thead th {
        border-bottom: solid 1px #000;
    }

    tbody tr.items-table-item td {
        border-bottom: 1px #000 solid;
    }

    tbody td.total {
        border-top: solid 1px #000;
    }
}
