@use "../utils/variables";

.notification {
    padding: 1rem;
    border-radius: variables.$default-border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    &.danger {
        color: variables.$danger-color;
        background: variables.$danger-color-background;
    }

    &.success {
        color: variables.$success-color;
        background: variables.$success-color-background;
    }

    &.info {
        color: variables.$primary-color;
        background: variables.$primary-color-transparent;
    }

    .close {
        align-self: baseline;
    }
}
