@use "../utils/variables";

.vertical-divider {
    margin: 0 1rem;
    width: 1px;
    background: variables.$border-color;
}

.horizontal-divider {
    height: 1px;
    width: 100%;
    background: variables.$border-color;
    margin-bottom: 1rem;
}
