@use "mixins";

$default-color: #1f2f43;
$default-color-lighter: #535353;

$body-background-color: #fdfdfd;
$border-color: #eee;
$primary-color: #0d5892;
$primary-color-background: lighten($primary-color, 60%);
$primary-color-hover: darken($primary-color, 5%);
$primary-color-active: darken($primary-color, 10%);
$primary-color-shadow: transparentize($primary-color, 0.8);
$primary-color-transparent: transparentize($primary-color, 0.95);
$primary-color-transparent-active: transparentize($primary-color, 0.9);

:root {
    --primary-color: #{$primary-color};
}

$danger-color: #a50009;
$danger-color-background: lighten($danger-color, 60%);
$danger-color-hover: darken($danger-color, 5%);
$danger-color-active: darken($danger-color, 10%);
$danger-color-transparent: transparentize($danger-color, 0.95);
$danger-color-transparent-active: transparentize($danger-color, 0.9);
$default-border-radius: 5px;

$success-color: #22623a;
$success-color-background: lighten($success-color, 60%);

$recurring-color: #750e6f;
$recurring-color-background: lighten($recurring-color, 60%);

$concept-color: #767677;
$concept-color-background: lighten($concept-color, 50%);

$reminded-color: #b46e12;
$reminded-color-background: lighten($reminded-color, 50%);

$changed-color: #c57000;
$changed-color-background: lighten($changed-color, 50%);

$transparent-color-hover: rgba(0, 0, 0, 0.05);

$zindex-dropdown:                   10 !default;
$zindex-sticky:                     11 !default;
$zindex-fixed:                      12 !default;
$zindex-modal-backdrop:             13 !default;
$zindex-modal:                      14 !default;
$zindex-popover:                    15 !default;
$zindex-tooltip:                    16 !default;
