@use "../utils/variables";
@use "buttons";

.paginate {
    .paginate-items {
        display: flex;
        align-items: center;
    }

    .paginate-item {
        .paginate-item-link {
            &:not(.active) {
                @extend .btn;
                @extend .btn-secondary;
                @extend .btn-small;
            }

            &.active {
                @extend .btn;
                @extend .btn-primary;
                @extend .btn-small;
            }
        }
    }

    .btn-next .next-link {
        border-left: none;
        @extend .btn;
        @extend .btn-light;
    }

    .btn-previous .previous-link {
        @extend .btn;
        @extend .btn-light;
    }

    .previous-link, .next-link, .paginate-item-link {
        display: block;
        padding: 0.5rem 1rem;
    }
}
