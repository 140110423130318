@use "sass:math";
@use "../utils/variables";

.form-group {
    margin-bottom: 1rem;
}

.form-control-container, .react-datepicker-wrapper {
    display: flex;
    border: solid 1px variables.$border-color;
    border-radius: variables.$default-border-radius;
    padding: 0.25rem 0.75rem;
    align-items: center;
    cursor: text;
    background: #fff;

    &.has-focus {
        border-color: variables.$primary-color;
    }

    &.has-error {
        border-color: variables.$danger-color;
    }

    .form-control-container-append {
        pointer-events: auto;
        margin-left: 0.75rem;
    }
}

.form-label {
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block;
}

.form-control {
    padding: 0.5rem 0;
    border-radius: variables.$default-border-radius;
    pointer-events: auto;

    &:not(.w-auto) {
        width: 100%;
    }

    &:focus {
        outline: none;
    }

    &.form-control-loading {
        cursor: wait;

        &:after {
            animation: loading 1s infinite;
            border-radius: variables.$primary-color;
            content: '';
            display: block;
            height: 4px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

.form-message {
    margin-top: 0.5rem;

    &.invalid {
        color: variables.$danger-color;
    }
}

.form-section {
    border-bottom: solid 1px variables.$border-color;
    margin-bottom: 1rem;
}

.form-row {
    display: flex;

    .form-group {
        flex: 1;

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
}

.form-helper {
    margin-top: 0.5rem;
    color: variables.$default-color-lighter;
}

.form-switcher {
    input[type="radio"] {
        visibility: hidden;
        width: 0;

        &:checked + .form-switcher-option {
            border-color: variables.$primary-color;
            background: variables.$primary-color-background;
        }
    }

    .form-switcher-option {
        border: solid 1px variables.$border-color;
        padding: 0.5rem;
        cursor: pointer;
        display: inline-block;

        &:first-of-type {
            border-top-left-radius: variables.$default-border-radius;
            border-bottom-left-radius: variables.$default-border-radius;
        }

        &:last-of-type {
            border-top-right-radius: variables.$default-border-radius;
            border-bottom-right-radius: variables.$default-border-radius;
        }
    }
}
