@use "../utils/variables";

body {
    background: variables.$body-background-color;
    font: {
        family: 'Montserrat', Helvetica, SansSerif;
    }
    color: variables.$default-color;
}

html, body {
    min-height: 100vh;
}

.react-grid-item.react-grid-placeholder {
    background: variables.$primary-color;
    opacity: 0.1;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
