@use "../utils/variables";

.status {
    padding: 0.5rem;
    border-radius: variables.$default-border-radius;
    display: inline-flex;
    align-items: center;
    font-weight: 500;

    &.status-small {
        padding: 0.25rem;
    }

    &.status-sent {
        color: variables.$success-color;
        background: variables.$success-color-background;
    }

    &.status-resent {
        color: variables.$success-color;
        background: variables.$success-color-background;
    }

    &.status-changed {
        color: variables.$changed-color;
        background: variables.$changed-color-background;
    }


    &.status-paid {
        color: variables.$primary-color;
        background: variables.$primary-color-background;
    }

    &.status-concept {
        color: variables.$concept-color;
        background: variables.$concept-color-background;
    }

    &.status-recurring {
        color: variables.$recurring-color;
        background: variables.$recurring-color-background;
    }

    &.status-expired {
        color: variables.$danger-color;
        background: variables.$danger-color-background;
    }

    &.status-reminded {
        color: variables.$reminded-color;
        background: variables.$reminded-color-background;
    }
}
