@use "../utils/variables";

.dropdown {
    position: relative;

    .dropdown-menu {
        display: none;
        background: #fff;
        position: absolute;
        z-index: variables.$zindex-dropdown;
        padding: 0.5rem 0;
        min-width: 10rem;
        box-shadow: 0 6px 10px 0 darken(variables.$border-color, 5%);
        border-radius: variables.$default-border-radius;
        cursor: default;

        &.show {
            display: block;
        }

        .dropdown-item {
            color: inherit;
            text-decoration: none;
            display: block;
            padding: 0.5rem 1rem;
            width: 100%;
            cursor: pointer;
            text-align: left;
            white-space: nowrap;

            &:hover {
                background: variables.$transparent-color-hover;
            }

            &:active {
                //background: variables.$color-light-active;
            }

            .dropdown-item-icon {
                display: inline-block;
                margin-right: 1rem;
            }
        }
    }

    .dropdown-divider {
        height: 1px;
        width: 100%;
        background: variables.$border-color;
        margin: 0.5rem 0;
    }

    .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
        right: auto;
        bottom: auto;
    }
}
