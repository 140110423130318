@use "../utils/variables";

.company-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &.loading:after {
        animation: loading 1s infinite;
        background: variables.$primary-color;
        border-radius: variables.$default-border-radius;
        content: '';
        display: block;
        height: 4px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        color: #0074D9;
    }

    .company-selector-name {
        font-weight: 600;
    }

    .company-selector-button {
        width: 100%;
        align-items: center;

        .icon {
            color: #959ba6;
            margin-left: auto;
        }

        .company-avatar {
            background: variables.$primary-color;
            padding: 1rem;
            font-size: 1rem;
            border-radius: variables.$default-border-radius;
            color: #fff;
            margin-right: 1rem;
        }

        h3 {
            margin-right: 0.5rem;
            text-align: left;
            color: currentColor;
        }
    }

    .floating-result {
        position: absolute;
        top: 100%;
        padding: 1rem;
        background: #fff;
        border: solid 1px variables.$border-color;
        border-radius: variables.$default-border-radius;
        width: 100%;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .03);
        z-index: 10;

        .user-company-list-item {
            border-radius: variables.$default-border-radius;
            padding: 1rem;
            display: flex;
            cursor: pointer;
            align-items: center;

            &.selected {
                cursor: default;
                background: variables.$primary-color-transparent;
            }

            &:hover:not(.selected) {
                background: variables.$transparent-color-hover;
            }

            .company-captions {
                display: flex;
                align-items: center;

                .company-name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .parent-company {
                justify-self: baseline;
                font-size: 0.9rem;
            }
        }
    }
}
