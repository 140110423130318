@use "../components/cards";
@use "../utils/variables";

.searcher-container {
    position: relative;

    .searcher-result-list {
        overflow-y: auto;
        max-height: 200px;
    }

    .searcher-result {
        @extend .card;
        position: absolute;
        width: 100%;
        top: calc(100% + 0.5rem);
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .03);
        padding: 1rem;
        z-index: 999;

        .searcher-result-item {
            cursor: pointer;
            padding: 1rem;
            display: block;
            border-radius: variables.$default-border-radius;

            &:hover {
                background: variables.$transparent-color-hover;
            }
        }
    }
}
